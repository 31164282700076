import { BigNumberish } from 'ethers'

// Collections -> Nfts -> Transactions
// Users -> Nft tokens IDs

// TODO: Handle the error state on the UI
export enum NFTMarketInitializationState {
  UNINITIALIZED = 'UNINITIALIZED',
  INITIALIZED = 'INITIALIZED',
  ERROR = 'ERROR',
}

export enum UserNftInitializationState {
  UNINITIALIZED = 'UNINITIALIZED',
  INITIALIZING = 'INITIALIZING',
  INITIALIZED = 'INITIALIZED',
  ERROR = 'ERROR',
}

export enum NftFilterLoadingState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}

export interface State {
  initializationState: NFTMarketInitializationState
  data: {
    collections: Record<string, Collection> // string is the address
    launchpads: Record<string, Launchpad> // string is the address
    nfts: Record<string, NftToken[]> // string is the collection address
    filters: Record<string, NftFilter> // string is the collection address
    activityFilters: Record<string, NftActivityFilter> // string is the collection address
    users: Record<string, User> // string is the address
    user: UserNftsState
    ethUsdPrice: number
    // usd price
  }
}

export interface UserNftsState {
  userNftsInitializationState: UserNftInitializationState
  nfts: NftToken[]
  activity: UserActivity
}

export interface Transaction {
  id: string
  block: string
  timestamp: string
  askPrice: string
  netPrice: string
  buyer: { id: string }
  seller: { id: string }
  nft?: TokenMarketData
}

export enum AskOrderType {
  NEW = 'New',
  MODIFY = 'Modify',
  CANCEL = 'Cancel',
}

export interface AskOrder {
  id: string
  block: string
  timestamp: string
  askPrice: string
  orderType: AskOrderType
  nft?: TokenMarketData
  seller?: { id: string }
}

export interface Image {
  original: string
  thumbnail: string
  mp4?: string
  mp3?: string
  gif?: string
  glb?: string
}

export enum NftLocation {
  FORSALE = 'For Sale',
  PROFILE = 'Profile Pic',
  WALLET = 'In Wallet',
}

// Market data regarding specific token ID, acquired via subgraph
export interface TokenMarketData {
  tokenId: string
  metadataUrl: string
  currentAskPrice: string
  currentSeller: string
  latestTradedPriceInETH: string
  tradeVolumeETH: string
  totalTrades: string
  isTradable: boolean
  otherId: string
  collection?: {
    id: string
  }
  updatedAt?: string
  transactionHistory?: Transaction[]
}

// Represents single NFT token,.
export interface NftToken {
  tokenId: string
  name: string
  description: string
  collectionName: string
  collectionAddress: string
  tokenURI?: string
  tokenUID?: string
  image: string
  video: string
  audio: string
  other: string
  attributes?: NftAttribute[]
  createdAt?: string // API createdAt
  updatedAt?: string // API updatedAt
  marketData?: TokenMarketData
  location?: NftLocation
  meta?: Record<string, string | number>
  onOrder?: number
  onOrderPrice?: string
}

export interface NftFilter {
  loadingState: NftFilterLoadingState
  activeFilters: Record<string, NftAttribute>
  showOnlyOnSale: boolean
  ordering: {
    field: string
    direction: 'asc' | 'desc'
  }
}

export interface NftActivityFilter {
  typeFilters: MarketEvent[]
}

export interface TokenIdWithCollectionAddress {
  collectionAddress: string
  tokenId: string
  nftLocation?: NftLocation
}

export interface NftAttribute {
  trait_type: string
  value: string
  display_type?: string
}

// Internal type used to refer to a collection
// Most fields are populated from API (via ApiCollection type)
export interface Collection {
  id: string
  address: string
  name: string
  description?: string
  symbol: string
  active: boolean
  totalVolumeETH: string
  numberTokensListed: string
  loyalityAddress?: string
  twitter?: string
  discord?: string
  website?: string
  refererFee: string
  creatorFee: string
  owner: string
  totalSupply: string
  verified: boolean
  avatar: string
  collectionUID?: string
  banner: string
  launchpadAvatar?: string
  attributes?: NftAttribute[]
  attributes_summary?: []
}

export interface Launchpad {
  id: string
  address: string
  active: boolean
  startBlock: string
  endBlock: string
  maxAlloc: string
  currentSupply: string
  price: string
  whitelist: string
}

export interface ApiCollections {
  [key: string]: Collection
}

export interface User {
  address: string
  numberTokensListed: BigNumberish
  numberTokensPurchased: BigNumberish
  numberTokensSold: BigNumberish
  nfts: Record<string, BigNumberish> // String is an address, BigNumberish is a tokenID
}

/**
 * API RESPONSES
 */

export interface ApiCollection {
  address: string
  owner: string
  name: string
  description: string
  symbol: string
  totalSupply: string
  verified: boolean
  createdAt: string
  updatedAt: string
  avatar: string
  twitter: string
  discord: string
  website: string
  banner: string
  creatorFee: number
  refererFee: number
  attributes?: NftAttribute[] // returned for specific collection but not for all collections
}

// Get all collections
// ${API_NFT}/collections/
export interface ApiCollectionsResponse {
  total: number
  data: ApiCollection[]
}

// Get single collection
// ${API_NFT}/collections/${collectionAddress}
export interface ApiSingleCollectionResponse {
  data: ApiCollection
}

// Get single collection
// ${API_NFT}/collections/${collectionAddress}
export interface ApiTokenFilterResponse {
  total: number
  data: Record<string, ApiSingleTokenData>
}

export interface ApiSingleTokenData {
  name: string
  image: string
  video: string
  audio: string
  other: string
  collection: {
    name: string
    description: string
    address?: string
  }
  attributes?: NftAttribute[]
  tokenId?: string
  tokenURI?: string
  tokenUID?: string
  onOrder?: number
  onOrderPrice?: string
}

// Get tokens within collection
// ${API_NFT}/collections/${collectionAddress}/tokens
export interface ApiResponseCollectionTokens {
  total: number
  attributesDistribution: Record<string, number>
  data: Record<string, ApiSingleTokenData>
}

// Get specific token data
// ${API_NFT}/collections/${collectionAddress}/tokens/${tokenId}
export interface ApiResponseSpecificToken {
  tokenId: string
  name: string
  description: string
  image: string
  video: string
  audio: string
  other: string
  createdAt: string
  updatedAt: string
  attributes: NftAttribute[]
  collection: {
    address: string
    name: string
    description: string
  }
  tokenURI: string
  tokenUID: string
  onOrder: number
  onOrderPrice: string
}
export interface ApiResponseTokens {
  data: [ApiResponseSpecificToken]
}

export interface ApiResponseTemplate {
  id: string
  owner: string
  content: string
  name: string
}

export interface ApiResponseLaunchpadPage {
  id: string
  address: string
  owner: string
  name: string
  content: string
}

// specific template
export interface ApiResponseTemplates {
  current_page: number
  data: ApiResponseTemplate[]
  first_page_url: string
  from: string
  last_page: number
  last_page_url: string
  links: []
  next_page_url: string
  path: string
  per_page: string
  prev_page_url: string
  to: string
  total: number
}

export interface ApiResponseLaunchpadPages {
  data: []
}

// ${API_NFT}/collections/${collectionAddress}/distribution
export interface ApiCollectionDistribution {
  total: number
  data: Record<string, Record<string, number>>
}

export interface ApiCollectionDistributionPB {
  total: number
  data: Record<string, number>
}

export interface Activity {
  marketEvent: MarketEvent
  timestamp: string
  tx: string
  nft?: TokenMarketData
  price?: string
  otherParty?: string
  buyer?: string
  seller?: string
}

export enum MarketEvent {
  NEW = 'NEW',
  CANCEL = 'CANCEL',
  MODIFY = 'MODIFY',
  BUY = 'BUY',
  SELL = 'SELL',
}

/**
 * SUBGRAPH RESPONSES
 */

export interface CollectionMarketDataBaseFields {
  id: string
  name: string
  symbol: string
  active: boolean
  totalTrades: string
  totalVolumeETH: string
  numberTokensListed: string
  creatorAddress: string
  refererFee: string
  creatorFee: string
  whitelistChecked: string
}

export interface UserActivity {
  askOrderHistory: AskOrder[]
  buyTradeHistory: Transaction[]
  sellTradeHistory: Transaction[]
  initializationState: UserNftInitializationState
}

export interface ApiImageUploadProps {
  ipfsHash: string
  filetype: string
}
export interface ApiNewNftDataProps {
  collection: string
  collectionUID: string
  name: string
  tokenURI: string
  image: string
}

export interface SignatureDataProps {
  v: number
  r: string
  s: string
}

export interface OrderDataProps {
  id: number
  chainId: number
  creator: string
  price: string
  data: string
  v: string
  r: string
  s: string
  isCompleted: number
}

export interface ApiCollectionDataProps {
  name: string
  description?: string
  symbol: string
  avatar: string
  banner: string
  owner: string
  loyalityAddress: string
  twitter: string
  discord: string
  website: string
  creatorFee: number
  refererFee: number
  attributes?: Array<{ trait_type: string; value: string }>
}
export interface ApiCollectionUpdateProps {
  collectionUID: string
  avatar: string
  banner: string
  owner: string
}
export interface ApiCollectionAddressUpdateProps {
  collectionUID: string
  address: string
}

export interface ApiIntroductionDataProps {
  collection: string
  tokenId: string
  content: string
  address: string
}

export interface ApiTemplateDataProps {
  content: string
  address: string
}
