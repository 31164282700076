import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'
import { BASE_URL } from './networks'

export const DEFAULT_META: PageMeta = {
  title: 'Ghost',
  description: 'NFT Marketplace . You can play with Ghost Squad.',
  image: `${BASE_URL}/images/hero.jpg`,
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('GHOST')}`,
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('GHOST')}`,
      }
    case '/nfts/createnft':
      return {
        title: `${t('Create NFT')} | ${t('GHOST')}`,
      }
    case '/nfts/createpad':
      return {
        title: `${t('Create Launchpad')} | ${t('GHOST')}`,
      }
    case '/nfts/mypage':
      return {
        title: `${t('Mypage')} | ${t('GHOST')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('GHOST')}`,
      }
    default:
      return null
  }
}
