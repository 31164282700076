// Set of helper functions to facilitate wallet setup

import { BASE_SCAN_URL } from 'config'
import { APP_CHAIN_ID, CHAIN_NAME, CHAIN_SYMBOL, CHAIN_SYMBOL_UPPER } from 'config/constants/tokens'
import { nodes } from 'config/constants/networks'

export const setupNetwork = async () => {
  const provider = window.ethereum
  if (provider) {
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${APP_CHAIN_ID.toString(16)}`,
            chainName: CHAIN_NAME,
            nativeCurrency: {
              name: CHAIN_SYMBOL_UPPER,
              symbol: CHAIN_SYMBOL,
              decimals: 18,
            },
            rpcUrls: nodes,
            blockExplorerUrls: [`${BASE_SCAN_URL}/`],
          },
        ],
      })
      return true
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
      return false
    }
  } else {
    console.error("Can't setup the ETH network on metamask because window.ethereum is undefined")
    return false
  }
}
