import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { GhostTheme } from '@ghost-nft/ghost-uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends GhostTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  .ql-tooltip {
    left: 0 !important;
  }
  .ql-video {
    width: 100%;
    height: 360px;
    }
`

export default GlobalStyle
