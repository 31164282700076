import { Token } from '@ghost-nft/eth-ghost-sdk'
import CHAIN_TOKEN_LIST from './tokenLists/chain.tokenlist.json'

export const APP_CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID, 10)

const chainTokens = CHAIN_TOKEN_LIST.tokens
const chainInfo = CHAIN_TOKEN_LIST.chainInfos[APP_CHAIN_ID]

export const CHAIN_TOKEN = chainTokens[chainInfo.token]
export const CHAIN_NAME = CHAIN_TOKEN.chainName
export const CHAIN_SYMBOL = CHAIN_TOKEN.symbol
export const CHAIN_SYMBOL_UPPER = CHAIN_SYMBOL.toUpperCase()
export const CHAIN_WRAP_SYMBOL = CHAIN_TOKEN.wrappedSymbol
export const CHAIN_WRAP_SYMBOL_UPPER = CHAIN_WRAP_SYMBOL.toUpperCase()

const token = new Token(
  chainInfo.chainId,
  chainInfo.address,
  CHAIN_TOKEN.decimals,
  CHAIN_TOKEN.symbol,
  CHAIN_TOKEN.name,
  CHAIN_TOKEN.projectLink,
)

export const tokens = {
  weth: token,
  eth: token,
}
