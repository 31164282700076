import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta'
import { useParams } from 'react-router-dom'
import { getNftApi } from 'state/nftMarket/helpers'
import Container from './Container'

const StyledPage = styled(Container)`
  min-height: calc(100vh - 64px);
  padding-top: 16px;
  padding-bottom: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`

export const PageMeta: React.FC<{ symbol?: string }> = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const { collectionAddress, tokenId } = useParams<{ collectionAddress: string; tokenId: string }>()
  // console.log('collectionAddress',collectionAddress)
  // console.log('tokenId',tokenId)
  const [nftMeta, setNftMeta] = useState({})
  useEffect(() => {
    const fetchNftMetaData = async () => {
      const metadata = await getNftApi(collectionAddress, tokenId)
      setNftMeta({
        tokenId,
        collectionAddress,
        title: metadata ? `${metadata.name} | GHOST` : 'GHOST',
        description: metadata.description,
        image: metadata.image,
        tokenURI: metadata.tokenURI,
        tokenUID: metadata.tokenUID,
        onOrder: metadata.onOrder,
        onOrderPrice: metadata.onOrderPrice,
      })
    }
    if (collectionAddress && tokenId) {
      fetchNftMetaData()
    }
  }, [collectionAddress, tokenId])

  // const collection = useGetCollection(collectionAddress)

  const pageMeta = getCustomMeta(pathname, t) || {}
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta, ...nftMeta }

  // console.log('title',title)
  // console.log('description',description)
  // console.log('image',image)

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Helmet>
  )
}

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  symbol?: string
}

const Page: React.FC<PageProps> = ({ children, ...props }) => {
  return (
    <>
      <PageMeta />
      <StyledPage {...props}>{children}</StyledPage>
    </>
  )
}

export default Page
