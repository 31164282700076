import { Contract } from '@ethersproject/contracts'
import { ethers } from 'ethers'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { JSBI, Percent } from '@ghost-nft/eth-ghost-sdk'
import { BASE_SCAN_URL } from '../config'
import { simpleRpcProvider } from './providers'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getScanLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
): string {
  switch (type) {
    case 'transaction': {
      return `${BASE_SCAN_URL}/tx/${data}`
    }
    case 'token': {
      return `${BASE_SCAN_URL}/token/${data}`
    }
    case 'block': {
      return `${BASE_SCAN_URL}/block/${data}`
    }
    case 'countdown': {
      return `${BASE_SCAN_URL}/block/countdown/${data}`
    }
    default: {
      return `${BASE_SCAN_URL}/address/${data}`
    }
  }
}

export function getScanLinkForCollection(collectionAddress: string): string {
  return `${BASE_SCAN_URL}/token/${collectionAddress}`
}

export function getScanLinkForNft(collectionAddress: string, tokenId: string): string {
  return `${BASE_SCAN_URL}/token/${collectionAddress}?a=${tokenId}`
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, signer?: ethers.Signer | ethers.providers.Provider): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, signer ?? simpleRpcProvider)
}

// account is optional

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
