import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'

// Addresses
import {
  getMulticallAddress,
  getNftMarketAddress,
  getGhostCollectionFactoryAddress,
  getGhostFeeManagerFactoryAddress,
  getGhostSharePotFactoryAddress,
  getGhostPadFactoryAddress,
  getGhostOrderProxyAddress,
  getGhostWhitelistFactoryAddress,
} from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import nftMarketAbi from 'config/abi/GhostNFTMarket.json'
import erc721CollectionAbi from 'config/abi/erc721collection.json'
import GhostCollectionFactoryAbi from 'config/abi/GhostCollectionFactory.json'
import GhostFeeManagerAbi from 'config/abi/GhostFeeManager.json'
import GhostFeeManagerFactoryAbi from 'config/abi/GhostFeeManagerFactory.json'
import GhostSharePotAbi from 'config/abi/GhostSharePot.json'
import GhostSharePotFactoryAbi from 'config/abi/GhostSharePotFactory.json'
import GhostPadFactoryAbi from 'config/abi/GhostPadFactory.json'
import GhostOrderProxyAbi from 'config/abi/GhostOrderProxy.json'
import GhostBaseCollectionAbi from 'config/abi/GhostBaseCollection.json'
import GhostWhitelistFactoryAbi from 'config/abi/GhostWhitelistFactory.json'
import GhostWhitelistAbi from 'config/abi/GhostWhitelist.json'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
export const getNftMarketContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftMarketAbi, getNftMarketAddress(), signer)
}
export const getGhostCollectionFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostCollectionFactoryAbi, getGhostCollectionFactoryAddress(), signer)
}
export const getGhostOrderProxyContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostOrderProxyAbi, getGhostOrderProxyAddress(), signer)
}
export const getGhostFeeManagerContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostFeeManagerAbi, address, signer)
}
export const getGhostFeeManagerFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostFeeManagerFactoryAbi, getGhostFeeManagerFactoryAddress(), signer)
}
export const getGhostSharePotContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostSharePotAbi, address, signer)
}
export const getGhostSharePotFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostSharePotFactoryAbi, getGhostSharePotFactoryAddress(), signer)
}
export const getGhostPadFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostPadFactoryAbi, getGhostPadFactoryAddress(), signer)
}
export const getErc721CollectionContract = (signer?: ethers.Signer | ethers.providers.Provider, address?: string) => {
  return getContract(erc721CollectionAbi, address, signer)
}
export const getGhostBaseCollectionContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostBaseCollectionAbi, address, signer)
}
export const getGhostWhitelistFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostWhitelistFactoryAbi, getGhostWhitelistFactoryAddress(), signer)
}
export const getGhostWhitelistContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostWhitelistAbi, address, signer)
}
