import { ChainId } from '@ghost-nft/eth-ghost-sdk'
import sample from 'lodash/sample'
import { APP_CHAIN_ID } from './tokens'

const NETWORK_URLS: { [chainId in ChainId]: string[] } = {
  [ChainId.ETHERNET]: ['https://mainnet.infura.io/v3/22428606873f442c83bf8aa6422fa6c1'],
  [ChainId.BSCNET]: [
    'https://rpc.ankr.com/bsc',
    'https://bsc-dataseed3.ninicoin.io',
    'https://bsc-dataseed.binance.org',
  ],
  [ChainId.LOCALNET]: ['http://localnode.ghostnft.io:8545'],
  [ChainId.GHOSTNET]: ['https://testnode.ghostnft.io'],
}

const SUBGRAPH_URLS: { [chainId in ChainId]: string } = {
  [ChainId.ETHERNET]: 'https://api.thegraph.com/subgraphs/name/ghost-nft/ghostethgraph',
  [ChainId.BSCNET]: 'https://api.thegraph.com/subgraphs/name/ghost-nft/ghostbscgraph',
  [ChainId.LOCALNET]: 'http://localnode.ghostnft.io:8000/subgraphs/name/ghost-nft/ghostethgraph',
  [ChainId.GHOSTNET]: 'https://testgraph.ghostnft.io/subgraphs/name/ghost-nft/ghostethgraph',
}

const APP_API_NFTS: { [chainId in ChainId]: string } = {
  [ChainId.ETHERNET]: 'https://api-eth.ghostnft.io/v1',
  [ChainId.BSCNET]: 'https://api-eth.ghostnft.io/v1',
  [ChainId.LOCALNET]: 'http://localnode.ghostnft.io:9999/v1',
  [ChainId.GHOSTNET]: 'https://testapi.ghostnft.io/v1',
}

const BASE_URLS: { [chainId in ChainId]: string } = {
  [ChainId.ETHERNET]: 'https://eth.ghostnft.io',
  [ChainId.BSCNET]: 'https://bsc.ghostnft.io',
  [ChainId.LOCALNET]: 'http://localhost:3000',
  [ChainId.GHOSTNET]: 'https://test.ghostnft.io',
}
export const BASE_URL = BASE_URLS[APP_CHAIN_ID]

export const GRAPH_API_NFTMARKET = SUBGRAPH_URLS[APP_CHAIN_ID]
export const nodes = NETWORK_URLS[APP_CHAIN_ID]
export const RPC_URL = sample(nodes)

export const API_NFT = `${APP_API_NFTS[APP_CHAIN_ID]}/${APP_CHAIN_ID}`

export const PINATA_APIKEY = '658c5d920a2072ae17b4'
export const PINATA_APISECRET = '859b5c068cd695fceaa739058954f28066cd8585d933089402261c27bc3783fb'
export const PINATA_JWT =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI1YWExZjM3NC05MGE5LTQyYTktOWExOC1iNjk3NjU3NDU0MzciLCJlbWFpbCI6ImxvY295YW1hNjZAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9LHsiaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6IjY1OGM1ZDkyMGEyMDcyYWUxN2I0Iiwic2NvcGVkS2V5U2VjcmV0IjoiODU5YjVjMDY4Y2Q2OTVmY2VhYTczOTA1ODk1NGYyODA2NmNkODU4NWQ5MzMwODk0MDIyNjFjMjdiYzM3ODNmYiIsImlhdCI6MTY4MDM3MTg4OH0.nmjTLNS5RfttgQIXkFETMWBpgaPKsdEOolhRepEyXl0'
// export const PINATA_GATEWAY = 'https://pinata.ghostnft.io/ipfs/'
export const PINATA_GATEWAY = 'https://ipfs.io/ipfs/'
