import { ChainId } from '@ghost-nft/eth-ghost-sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { APP_CHAIN_ID } from 'config/constants/tokens'

export const getAddress = (address: Address): string => {
  return address[APP_CHAIN_ID] ?? address[ChainId.ETHERNET]
}
export const getChainLinkAddress = () => {
  return getAddress(addresses.chainLink)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getGhostCollectionFactoryAddress = () => {
  return getAddress(addresses.ghostCollectionFactory)
}
export const getGhostOrderProxyAddress = () => {
  return getAddress(addresses.ghostOrderProxy)
}
export const getGhostFeeManagerFactoryAddress = () => {
  return getAddress(addresses.ghostFeeManagerFactory)
}
export const getGhostSharePotFactoryAddress = () => {
  return getAddress(addresses.ghostSharePotFactory)
}
export const getGhostPadFactoryAddress = () => {
  return getAddress(addresses.ghostPadFactory)
}
export const getGhostWhitelistFactoryAddress = () => {
  return getAddress(addresses.ghostWhitelistFactory)
}
