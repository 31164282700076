import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AppState } from 'state'
import { getGhostWhitelistFactoryContract } from 'utils/contractHelpers'
import multicall from 'utils/multicall'
import GhostWhitelistFactoryAbi from 'config/abi/GhostWhitelistFactory.json'
import { WhitelistState, SerializedWhitelist } from '../types'

const initialState: WhitelistState = {
  data: [],
  userDataLoaded: false,
}

// Async thunks
export const fetchGhostWhitelistDataAsync = createAsyncThunk<
  SerializedWhitelist[],
  string,
  {
    state: AppState
  }
>('feeManager/fetchGhostWhitelistDataAsync', async () => {
  const whitelistContract = getGhostWhitelistFactoryContract()
  const whitelists = await whitelistContract.getAddresses()
  const existWhitelist = whitelists.filter((v) => v !== '0x0000000000000000000000000000000000000000')
  const calls = existWhitelist.flatMap((value) => {
    return [
      {
        address: whitelistContract.address,
        name: 'whitelistInfo',
        params: [value],
      },
    ]
  })
  const results = await multicall(GhostWhitelistFactoryAbi, calls)
  return results.map((data) => {
    const { whitelist, name, owner } = data
    return {
      address: whitelist,
      name,
      owner,
    }
  })
})
export const whitelistSlice = createSlice({
  name: 'GhostWhitelistFactory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchGhostWhitelistDataAsync.fulfilled, (state, action) => {
      state.data = action.payload
      state.userDataLoaded = true
    })
  },
})

export default whitelistSlice.reducer
