import { createReducer } from '@reduxjs/toolkit'
import { updateVersion } from '../global/actions'
import { muteAudio, toggleTheme, unmuteAudio, hidePhishingWarningBanner } from './actions'

const currentTimestamp = () => new Date().getTime()

export interface UserState {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number
  timestamp: number
  audioPlay: boolean
  isDark: boolean
  showPhishingWarningBanner: boolean
}

export const initialState: UserState = {
  timestamp: currentTimestamp(),
  audioPlay: true,
  isDark: false,
  showPhishingWarningBanner: true,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateVersion, (state) => {
      state.lastUpdateVersionTimestamp = currentTimestamp()
    })
    .addCase(muteAudio, (state) => {
      state.audioPlay = false
    })
    .addCase(unmuteAudio, (state) => {
      state.audioPlay = true
    })
    .addCase(toggleTheme, (state) => {
      state.isDark = !state.isDark
    })
    .addCase(hidePhishingWarningBanner, (state) => {
      state.showPhishingWarningBanner = false
    }),
)
