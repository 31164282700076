import BigNumber from 'bignumber.js/bignumber'
import { CHAIN_TOKEN } from './constants/tokens'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BASE_SCAN_URL = CHAIN_TOKEN.scanUrl
export const BASE_BLOCK_TIME = CHAIN_TOKEN.blockTime
