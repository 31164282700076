export default {
  multiCall: {
    1: '0x7313aF5F048d12458bF3B5714df13993D51e60fF',
    56: '0x4C8c277755aCaBBd43077389e2f4018d4907112D',
    1337: '0x2CEC19Ac911265662192a4F634f4CC8451462512',
    9234: '0x2CEC19Ac911265662192a4F634f4CC8451462512',
  },
  nftMarket: {
    1: '0xf140f20a0690d1A83242510Ab4180Ba811274ba9',
    56: '0x84cED12Ba99eE9c43494620Cc0BB9210cD20Fa1C',
    1337: '0x08febA79fEcB1fA605AA0f465DFF470CD6E786AE',
    9234: '0x08febA79fEcB1fA605AA0f465DFF470CD6E786AE',
  },
  ghostCollectionFactory: {
    1: '0xADf9b28F8d157429164C38178d133C41FC3042c3',
    56: '0xF4d8A046B180775D1dC6a80Aaa79b3828bf699C6',
    1337: '0x092b7E0E81C8AE9ac68E2A208Ebd7305B02c2806',
    9234: '0x092b7E0E81C8AE9ac68E2A208Ebd7305B02c2806',
  },
  ghostFeeManagerFactory: {
    1: '0x616f8F1c38733d7C6635a29DC0db992179aE22b1',
    56: '0x1960014EB76C5a5C4f1ee90E0FD49EBcBb5Af771',
    1337: '0x939146c8bfB5f2cF70EAe5f8D945535ab5f039cA',
    9234: '0x939146c8bfB5f2cF70EAe5f8D945535ab5f039cA',
  },
  ghostSharePotFactory: {
    1: '0xa270d20F5F1Bc5C20d01f72D82E4c1346Ef59b8C',
    56: '0x159992508BEFffA2264184Ef474455E4a0b826BE',
    1337: '0x6b0C47d69E9d239568BEB026972e15Ba39eeBf7E',
    9234: '0x6b0C47d69E9d239568BEB026972e15Ba39eeBf7E',
  },
  ghostPadFactory: {
    1: '0x4c13707d7aE6ddeAAA170f2B2D89960F792A5365',
    56: '0xc72AeA2db5a8185ADe2B9700CCf92d43af2B9434',
    1337: '0xDEC680Fc0d45C803c3a70215F40A21c792Bd1043',
    9234: '0xDEC680Fc0d45C803c3a70215F40A21c792Bd1043',
  },
  ghostOrderProxy: {
    1: '0x73944aB1a3Ab52B68D9E4c62FAF8aF6F570c9cc2',
    56: '0x345699905dabcC0A539eAD5c2714E8C83aa55a96',
    1337: '0xE90BedcE34864878f078092918a3768e94EBCA64',
    9234: '0xE90BedcE34864878f078092918a3768e94EBCA64',
  },
  ghostWhitelistFactory: {
    1: '0xEB4CE0F8BB3a803C96533BF461982E90da519786',
    56: '0x969Bece7DAA97c533270a309aABc7cdAeDF37d30',
    1337: '0x05BdADd192F7e5B128284b961d65380E704d481D',
    9234: '0x05BdADd192F7e5B128284b961d65380E704d481D',
  },
  chainLink: {
    1: '0xee9f2375b4bdf6387aa8265dd4fb8f16512a1d46',
    56: '0xd5c40f5144848bd4ef08a9605d860e727b991513',
    1337: '',
    9234: '',
  },
}
