import React, { useMemo, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { BASE_URL, nodes } from 'config/constants/networks'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { isUserRejected } from 'utils/sentry'
import useActiveWeb3React from './hooks/useActiveWeb3React'
import { BLOCKED_ADDRESSES } from './config/constants'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import App from './App'
import Providers from './Providers'

function Updaters() {
  return (
    <>
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

function Blocklist({ children }: { children: ReactNode }) {
  const { account } = useActiveWeb3React()
  const blocked: boolean = useMemo(() => Boolean(account && BLOCKED_ADDRESSES.indexOf(account) !== -1), [account])
  if (blocked) {
    return <div>Blocked address</div>
  }
  return <>{children}</>
}

// Sentry.init({
//   dsn: 'https://ed98e16b9d704c22bef92d24bdd5f3b7@o1092725.ingest.sentry.io/6111410',
//   integrations: [
//     new Integrations.BrowserTracing(),
//     new Sentry.Integrations.Breadcrumbs({
//       console: process.env.NODE_ENV === 'production',
//     }),
//   ],
//   environment: process.env.NODE_ENV,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.1,
//   beforeSend(event, hint) {
//     const error = hint?.originalException
//     if (error && isUserRejected(error)) {
//       return null
//     }
//     return event
//   },
//   ignoreErrors: [
//     'User denied transaction signature',
//     'Non-Error promise rejection captured',
//     'User rejected the transaction',
//     'cancelled',
//   ],
// })

ReactDOM.render(
  <React.StrictMode>
    <Blocklist>
      <Providers>
        <Updaters />
        <HelmetProvider>
          <Helmet>
            {nodes.map((node) => {
              return <link rel="preconnect" href={node} key={node} />
            })}
            <link rel="shortcut icon" href={`${BASE_URL}/favicon.ico`} />
            <link rel="apple-touch-icon" href={`${BASE_URL}/logo.png`} />
            <link rel="manifest" href={`${BASE_URL}/manifest.json`} />
            <meta name="twitter:image" content={`${BASE_URL}/images/hero.jpg`} />
          </Helmet>
          <App />
        </HelmetProvider>
      </Providers>
    </Blocklist>
  </React.StrictMode>,
  document.getElementById('root'),
)
