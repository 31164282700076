import React from 'react'
import { Box, Button, Flex, InjectedModalProps, LinkExternal, Message, Skeleton, Text } from '@ghost-nft/ghost-uikit'
import { useWeb3React } from '@web3-react/core'
import { FetchStatus, useGetEthBalance } from 'hooks/useTokenBalance'
import useAuth from 'hooks/useAuth'
import { CHAIN_TOKEN } from 'config/constants/tokens'
import { useTranslation } from 'contexts/Localization'
import { getScanLink } from 'utils'
import { formatBigNumber } from 'utils/formatBalance'
import CopyAddress from './CopyAddress'

interface WalletInfoProps {
  hasLowEthBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowEthBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { balance, fetchStatus } = useGetEthBalance()
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss()
    logout()
  }

  return (
    <>
      <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('Your Address')}
      </Text>
      <CopyAddress account={account} mb="24px" />
      {hasLowEthBalance && (
        <Message variant="warning" mb="24px">
          <Box>
            <Text fontWeight="bold">
              {t('%chainToken% Balance Low', { chainToken: CHAIN_TOKEN.symbol.toUpperCase() })}
            </Text>
            <Text as="p">
              {t('You need %chainToken% for transaction fees.', { chainToken: CHAIN_TOKEN.symbol.toUpperCase() })}
            </Text>
          </Box>
        </Message>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        <Text color="textSubtle">{t('%chainToken% Balance', { chainToken: CHAIN_TOKEN.symbol.toUpperCase() })}</Text>
        {fetchStatus !== FetchStatus.SUCCESS ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text>{formatBigNumber(balance, 6)}</Text>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="end" mb="24px">
        <LinkExternal href={getScanLink(account, 'address')}>
          {t('View on %scanName%', { scanName: CHAIN_TOKEN.scanName })}
        </LinkExternal>
      </Flex>
      <Button variant="secondary" width="100%" onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
    </>
  )
}

export default WalletInfo
