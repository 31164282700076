import { MenuItemsType, DropdownMenuItemType } from '@ghost-nft/ghost-uikit'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('NFT'),
    href: `${nftsBaseUrl}`,
    icon: 'Nft',
    items: [
      {
        label: t('Overview'),
        href: `${nftsBaseUrl}`,
      },
      {
        label: t('Collections'),
        href: `${nftsBaseUrl}/collections`,
      },
      {
        label: t('Launchpads'),
        href: `${nftsBaseUrl}/launchpads`,
      },
      {
        label: t('Mypage'),
        href: `${nftsBaseUrl}/mypage`,
      },
    ],
  },
  {
    label: '',
    href: '#',
    icon: 'More',
    hideSubNav: true,
    items: [
      {
        label: t('Blog'),
        href: 'https://medium.com/@ghost-nft',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Docs'),
        href: 'https://docs.ghostnft.io',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Convert'),
        href: `/convert`,
      },
    ],
  },
]

export default config
