import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AppState } from 'state'
import { getGhostFeeManagerFactoryContract } from 'utils/contractHelpers'
import multicall from 'utils/multicall'
import GhostFeeManagerAbi from 'config/abi/GhostFeeManager.json'
import { FeeManagerState, SerializedFeeManager } from '../types'

const initialState: FeeManagerState = {
  data: [],
  userDataLoaded: false,
}

// Async thunks
export const fetchGhostFeeManagerDataAsync = createAsyncThunk<
  SerializedFeeManager[],
  string,
  {
    state: AppState
  }
>('feeManager/fetchGhostFeeManagerDataAsync', async (address) => {
  const feeManagerContract = getGhostFeeManagerFactoryContract()
  const feeManagers = await feeManagerContract.getFeeManagerAddress()
  const existFeeManagers = feeManagers.filter((v) => v !== '0x0000000000000000000000000000000000000000')
  const calls = existFeeManagers.flatMap((manager) => {
    return [
      {
        address: manager,
        name: 'name',
      },
      {
        address: manager,
        name: 'owner',
      },
      {
        address: manager,
        name: 'totalLevel',
      },
      {
        address: manager,
        name: 'levels',
        params: [address],
      },
      {
        address: manager,
        name: 'pendingReward',
        params: [address],
      },
    ]
  })
  const results = await multicall(GhostFeeManagerAbi, calls)
  const FeeManagerData = results.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 5)

    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [existFeeManagers[chunkIndex]] // start a new chunk
    }
    resultArray[chunkIndex].push(item[0])
    return resultArray
  }, [])

  return FeeManagerData.map((data) => {
    const [feeManagerAddress, name, owner, totalLevel, levels, pendingReward] = data
    return {
      address: feeManagerAddress,
      name,
      owner,
      totalLevel: totalLevel.toString(),
      userData: {
        levels: levels.toString(),
        pendingReward: pendingReward.toString(),
      },
    }
  })
})
export const feeManagerSlice = createSlice({
  name: 'GhostFeeManagerFactory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchGhostFeeManagerDataAsync.fulfilled, (state, action) => {
      state.data = action.payload
      state.userDataLoaded = true
    })
  },
})

export default feeManagerSlice.reducer
