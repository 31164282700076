import { gql, request } from 'graphql-request'
import { API_NFT, GRAPH_API_NFTMARKET, PINATA_GATEWAY, PINATA_JWT, RPC_URL } from 'config/constants/networks'
import { getGhostPadFactoryContract, getGhostBaseCollectionContract } from 'utils/contractHelpers'
import multicall from 'utils/multicall'
import map from 'lodash/map'
import GhostPadFactoryAbi from 'config/abi/GhostPadFactory.json'
import GhostBaseCollectionAbi from 'config/abi/GhostBaseCollection.json'
import aggregatorV3InterfaceABI from 'config/abi/chainLink.json'
import axios from 'axios'
import { getChainLinkAddress } from 'utils/addressHelpers'
import { isAddress } from 'ethers/lib/utils'
import { formatBigNumber } from 'utils/formatBalance'
import { APP_CHAIN_ID, CHAIN_TOKEN } from 'config/constants/tokens'
import { ethers } from 'ethers'
import {
  ApiCollection,
  ApiCollections,
  ApiResponseCollectionTokens,
  ApiResponseSpecificToken,
  ApiResponseTemplates,
  ApiResponseTemplate,
  AskOrderType,
  Collection,
  CollectionMarketDataBaseFields,
  NftActivityFilter,
  NftLocation,
  NftToken,
  TokenIdWithCollectionAddress,
  TokenMarketData,
  Transaction,
  AskOrder,
  ApiCollectionsResponse,
  MarketEvent,
  ApiImageUploadProps,
  ApiNewNftDataProps,
  SignatureDataProps,
  ApiCollectionDataProps,
  ApiIntroductionDataProps,
  OrderDataProps,
  Launchpad,
  ApiResponseLaunchpadPage,
  ApiResponseLaunchpadPages,
  ApiResponseTokens,
} from './types'
import { getBaseNftFields, getBaseTransactionFields, getCollectionBaseFields } from './queries'

/**
 * API HELPERS
 */

/**
 * Fetch static data from all collections using the API
 * @returns
 */
export const getCollectionsApi = async (): Promise<ApiCollectionsResponse> => {
  const res = await fetch(`${API_NFT}/collections`)
  if (res.ok) {
    const json = await res.json()
    return json
  }
  console.error('Failed to fetch NFT collections', res.statusText)
  return null
}

/**
 * Fetch static data from a collection using the API
 * @returns
 */
export const getCollectionApi = async (collectionAddress: string): Promise<ApiCollection> => {
  const res = await fetch(`${API_NFT}/collections/${collectionAddress}`)
  if (res.ok) {
    const json = await res.json()
    return json.data
  }
  console.error(`API: Failed to fetch NFT collection ${collectionAddress}`, res.statusText)
  return null
}

/**
 * Fetch static data for all nfts in a collection using the API
 * @param collectionAddress
 * @param size
 * @param page
 * @returns
 */
export const getNftsFromCollectionApi = async (
  collectionAddress: string,
  size = 100,
  page = 1,
): Promise<ApiResponseCollectionTokens> => {
  const requestPath = `${API_NFT}/collections/${collectionAddress}/tokens?page=${page}&size=${size}`
  const res = await fetch(requestPath)
  if (res.ok) {
    const data = await res.json()
    return data
  }
  console.error(`API: Failed to fetch NFT tokens for ${collectionAddress} collection`, res.statusText)
  return null
}

export const getCollectionOwnerTokensFromApi = async (account: string): Promise<ApiResponseCollectionTokens> => {
  const requestPath = `${API_NFT}/collections/ownertokens?account=${account}`
  const res = await fetch(requestPath)
  if (res.ok) {
    const data = await res.json()
    return data
  }
  console.error(`API: Failed to fetch NFT tokens for ${account} collection`, res.statusText)
  return null
}

/**
 * Fetch a single NFT using the API
 * @param collectionAddress
 * @param tokenId
 * @returns NFT from API
 */
export const getNftApi = async (collectionAddress: string, tokenId: string): Promise<ApiResponseSpecificToken> => {
  const res = await fetch(`${API_NFT}/collections/${collectionAddress}/tokens/${tokenId}`)
  if (res.ok) {
    const json = await res.json()
    return json.data
  }

  console.error(`API: Can't fetch NFT token ${tokenId} in ${collectionAddress}`, res.status)
  return null
}

export const fetchNftApi = async (collectionAddress: string, tokenIds: number[]): Promise<ApiResponseTokens> => {
  const res = await axios.post(`${API_NFT}/collections/${collectionAddress}/fetch`, {
    tokenIds,
  })
  if (res.status === 200) {
    return res.data
  }

  console.error(`API: Can't fetch NFT token in ${collectionAddress}`, res.status)
  return null
}

/**
 * Fetch a list of NFT from different collections
 * @param from Array of { collectionAddress: string; tokenId: string }
 * @returns Array of NFT from API
 */
export const getNftsFromDifferentCollectionsApi = async (
  from: { collectionAddress: string; tokenId: string }[],
): Promise<NftToken[]> => {
  const collectionTokenIds = from.reduce((a, v) => {
    const c = a
    c[v.collectionAddress] = a[v.collectionAddress] || []
    c[v.collectionAddress].push(v.tokenId)
    return c
  }, {})

  const promises = Object.keys(collectionTokenIds).map((collectionAddress) => {
    return fetchNftApi(collectionAddress, collectionTokenIds[collectionAddress])
  })
  const responses = await Promise.all(promises)

  // Sometimes API can't find some tokens (e.g. 404 response)
  // at least return the ones that returned successfully
  const result = responses
    .filter((resp) => resp.data.length > 0)
    .reduce((pre, cur) => {
      return pre.concat(
        cur.data.map((res) => ({
          tokenId: res.tokenId,
          name: res.name,
          collectionName: res.collection.name,
          collectionAddress: res.collection.address,
          description: res.collection.description,
          attributes: res.attributes,
          createdAt: res.createdAt,
          updatedAt: res.updatedAt,
          image: res.image,
          video: res.video,
          audio: res.audio,
          other: res.other,
          tokenURI: res.tokenURI,
          tokenUID: res.tokenUID,
          onOrder: res.onOrder,
          onOrderPrice: res.onOrderPrice,
        })),
      )
    }, [])
  return result
}

/**
 * Fetch a template using the API
 * @param account
 * @returns Template from api
 */
export const getTemplateApi = async (account: string, size = 10, page = 1): Promise<ApiResponseTemplates> => {
  const res = await axios.get(`${API_NFT}/templates?owner=${account}&size=${size}&page=${page}`)
  if (res.status === 200) {
    return res.data
  }
  console.error(`API: Can't fetch templates from ${account}`, res.status)
  return null
}
export const getLaunchpadPageApi = async (collectionAddress: string): Promise<ApiResponseLaunchpadPages> => {
  const res = await axios.get(`${API_NFT}/launchpads/${collectionAddress}`)
  if (res.status === 200) {
    return res.data
  }
  console.error(`API: Can't fetch launchpad pages from ${collectionAddress}`, res.status)
  return null
}
/**
 * SUBGRAPH HELPERS
 */

/**
 * Fetch market data from a collection using the Subgraph
 * @returns
 */
export const getCollectionSg = async (collectionAddress: string): Promise<CollectionMarketDataBaseFields> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getCollectionData($collectionAddress: String!) {
          collection(id: $collectionAddress) {
            ${getCollectionBaseFields()}
          }
        }
      `,
      { collectionAddress: collectionAddress.toLowerCase() },
    )
    return res.collection
  } catch (error) {
    console.error('Failed to fetch collection', error)
    return null
  }
}

/**
 * Fetch market data from all collections using the Subgraph
 * @returns
 */
export const getCollectionsSg = async (): Promise<CollectionMarketDataBaseFields[]> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        {
          collections {
            ${getCollectionBaseFields()}
          }
        }
      `,
    )
    return res.collections
  } catch (error) {
    console.error('Failed to fetch NFT collections', error)
    return []
  }
}

/**
 * Fetch market data for nfts in a collection using the Subgraph
 * @param collectionAddress
 * @param first
 * @param skip
 * @returns
 */
export const getNftsFromCollectionSg = async (
  collectionAddress: string,
  first = 1000,
  skip = 0,
): Promise<TokenMarketData[]> => {
  // Squad to be sorted by tokenId as this matches the order of the paginated API return. For PBs - get the most recent,

  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getNftCollectionMarketData($collectionAddress: String!) {
          collection(id: $collectionAddress) {
            id
            nfts(orderBy:tokenId, skip: $skip, first: $first) {
             ${getBaseNftFields()}
            }
          }
        }
      `,
      { collectionAddress: collectionAddress.toLowerCase(), skip, first },
    )
    return res.collection.nfts
  } catch (error) {
    console.error('Failed to fetch NFTs from collection', error)
    return []
  }
}

/**
 * Fetch market data for PancakeBunnies NFTs by bunny id using the Subgraph
 * @param collectionAddress - collection address
 * @param existingTokenIds - tokens that are already loaded into redux
 * @returns
 */
export const getMarketDataForTokenIds = async (
  collectionAddress: string,
  existingTokenIds: string[],
): Promise<TokenMarketData[]> => {
  try {
    if (existingTokenIds.length === 0) {
      return []
    }
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getMarketDataForTokenIds($collectionAddress: String!, $where: NFT_filter) {
          collection(id: $collectionAddress) {
            id
            nfts(first: 1000, where: $where) {
              ${getBaseNftFields()}
            }
          }
        }
      `,
      {
        collectionAddress: collectionAddress.toLowerCase(),
        where: { tokenId_in: existingTokenIds },
      },
    )

    return res.collection.nfts
  } catch (error) {
    console.error(`Failed to fetch market data for NFTs stored tokens`, error)
    return []
  }
}

export const getNftsMarketData = async (
  where = {},
  first = 1000,
  orderBy = 'id',
  orderDirection: 'asc' | 'desc' = 'desc',
  skip = 0,
): Promise<TokenMarketData[]> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getNftsMarketData($first: Int, $skip: Int!, $where: NFT_filter, $orderBy: NFT_orderBy, $orderDirection: OrderDirection) {
          nfts(where: $where, first: $first, orderBy: $orderBy, orderDirection: $orderDirection, skip: $skip) {
            ${getBaseNftFields()}
            transactionHistory {
              ${getBaseTransactionFields()}
            }
          }
        }
      `,
      { where, first, skip, orderBy, orderDirection },
    )

    return res.nfts
  } catch (error) {
    console.error('Failed to fetch NFTs market data', error)
    return []
  }
}

export const getOrderDataFromApi = async (creator): Promise<TokenMarketData[]> => {
  try {
    const res = await axios.get(`${API_NFT}/orders/search?creator=${creator}&onOrder=1`)
    if (res.status === 200) {
      const result = res.data.data.map((nft) => {
        return {
          tokenId: nft.tokenId,
          metadataUrl: null,
          currentAskPrice: nft.onOrderPrice,
          currentSeller: creator,
          latestTradedPriceInETH: null,
          tradeVolumeETH: null,
          totalTrades: null,
          isTradable: true,
          otherId: null,
          collection: {
            id: nft.collection.address,
          },
          updatedAt: nft.updated_at,
          transactionHistory: [],
        }
      })
      return result
    }
    return []
  } catch (error) {
    console.error('Failed to fetch NFTs market data', error)
    return []
  }
}
export const getOrderDataFromCollectionApi = async (collectionAddress): Promise<TokenMarketData[]> => {
  try {
    const res = await axios.get(`${API_NFT}/orders/search?collection=${collectionAddress}&onOrder=1`)
    if (res.status === 200) {
      const result = res.data.data.map((nft) => {
        return {
          tokenId: nft.tokenId,
          metadataUrl: null,
          currentAskPrice: nft.onOrderPrice,
          currentSeller: nft.order.creator,
          latestTradedPriceInETH: null,
          tradeVolumeETH: null,
          totalTrades: null,
          isTradable: true,
          otherId: null,
          collection: {
            id: nft.collection.address,
          },
          updatedAt: nft.updated_at,
          transactionHistory: [],
        }
      })
      return result
    }
    return []
  } catch (error) {
    console.error('Failed to fetch NFTs market data', error)
    return []
  }
}
export const getNftsOrderDataFromApi = async (collectionAddress, tokenId): Promise<TokenMarketData[]> => {
  try {
    const res = await axios.get(`${API_NFT}/orders/search?collection=${collectionAddress}&tokenId=${tokenId}&onOrder=1`)
    if (res.status === 200) {
      const nft = res.data.data
      return [
        {
          tokenId: nft.tokenId,
          metadataUrl: null,
          currentAskPrice: nft.onOrderPrice,
          currentSeller: nft.order.creator,
          latestTradedPriceInETH: null,
          tradeVolumeETH: null,
          totalTrades: null,
          isTradable: true,
          otherId: null,
          collection: {
            id: nft.collection.address,
          },
          updatedAt: nft.updated_at,
          transactionHistory: [],
        },
      ]
    }
    return []
  } catch (error) {
    console.error('Failed to fetch NFTs market data', error)
    return []
  }
}

/**
 * Returns the lowest price of any NFT in a collection
 */
export const getLowestPriceInCollection = async (collectionAddress: string) => {
  try {
    const response = await getNftsMarketData(
      { collection: collectionAddress.toLowerCase(), isTradable: true },
      1,
      'currentAskPrice',
      'asc',
    )

    if (response.length === 0) {
      return 0
    }

    const [nftSg] = response
    return parseFloat(nftSg.currentAskPrice)
  } catch (error) {
    console.error(`Failed to lowest price NFTs in collection ${collectionAddress}`, error)
    return 0
  }
}

/**
 * Fetch user trading data for buyTradeHistory, sellTradeHistory and askOrderHistory from the Subgraph
 * @param where a User_filter where condition
 * @returns a UserActivity object
 */
export const getUserActivity = async (
  address: string,
): Promise<{ askOrderHistory: AskOrder[]; buyTradeHistory: Transaction[]; sellTradeHistory: Transaction[] }> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getUserActivity($address: String!) {
          user(id: $address) {
            buyTradeHistory(first: 250, orderBy: timestamp, orderDirection: desc) {
              ${getBaseTransactionFields()}
              nft {
                ${getBaseNftFields()}
              }
            }
            sellTradeHistory(first: 250, orderBy: timestamp, orderDirection: desc) {
              ${getBaseTransactionFields()}
              nft {
                ${getBaseNftFields()}
              }
            }
            askOrderHistory(first: 500, orderBy: timestamp, orderDirection: desc) {
              id
              block
              timestamp
              orderType
              askPrice
              nft {
                ${getBaseNftFields()}
              }
            }
          }
        }
      `,
      { address },
    )

    return res.user || { askOrderHistory: [], buyTradeHistory: [], sellTradeHistory: [] }
  } catch (error) {
    console.error('Failed to fetch user Activity', error)
    return {
      askOrderHistory: [],
      buyTradeHistory: [],
      sellTradeHistory: [],
    }
  }
}

export const getCollectionActivity = async (
  address: string,
  nftActivityFilter: NftActivityFilter,
  itemPerQuery,
): Promise<{ askOrders?: AskOrder[]; transactions?: Transaction[] }> => {
  const getAskOrderEvent = (orderType: MarketEvent): AskOrderType => {
    switch (orderType) {
      case MarketEvent.CANCEL:
        return AskOrderType.CANCEL
      case MarketEvent.MODIFY:
        return AskOrderType.MODIFY
      case MarketEvent.NEW:
        return AskOrderType.NEW
      default:
        return AskOrderType.MODIFY
    }
  }

  const isFetchAllCollections = address === ''

  const collectionFilterGql = !isFetchAllCollections ? `collection: ${JSON.stringify(address)}` : ``

  const askOrderTypeFilter = nftActivityFilter.typeFilters
    .filter((marketEvent) => marketEvent !== MarketEvent.SELL)
    .map((marketEvent) => getAskOrderEvent(marketEvent))

  const askOrderIncluded = nftActivityFilter.typeFilters.length === 0 || askOrderTypeFilter.length > 0

  const askOrderTypeFilterGql =
    askOrderTypeFilter.length > 0 ? `orderType_in: ${JSON.stringify(askOrderTypeFilter)}` : ``

  const transactionIncluded =
    nftActivityFilter.typeFilters.length === 0 ||
    nftActivityFilter.typeFilters.some(
      (marketEvent) => marketEvent === MarketEvent.BUY || marketEvent === MarketEvent.SELL,
    )

  let askOrderQueryItem = itemPerQuery / 2
  let transactionQueryItem = itemPerQuery / 2

  if (!askOrderIncluded || !transactionIncluded) {
    askOrderQueryItem = !askOrderIncluded ? 0 : itemPerQuery
    transactionQueryItem = !transactionIncluded ? 0 : itemPerQuery
  }

  const askOrderGql = askOrderIncluded
    ? `askOrders(first: ${askOrderQueryItem}, orderBy: timestamp, orderDirection: desc, where:{
            ${collectionFilterGql}, ${askOrderTypeFilterGql}
          }) {
              id
              block
              timestamp
              orderType
              askPrice
              seller {
                id
              }
              nft {
                ${getBaseNftFields()}
              }
          }`
    : ``

  const transactionGql = transactionIncluded
    ? `transactions(first: ${transactionQueryItem}, orderBy: timestamp, orderDirection: desc, where:{
            ${collectionFilterGql}
          }) {
            ${getBaseTransactionFields()}
              nft {
                ${getBaseNftFields()}
              }
          }`
    : ``

  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getCollectionActivity {
          ${askOrderGql}
          ${transactionGql}
        }
      `,
    )

    return res || { askOrders: [], transactions: [] }
  } catch (error) {
    console.error('Failed to fetch collection Activity', error)
    return {
      askOrders: [],
      transactions: [],
    }
  }
}

export const getTokenActivity = async (
  tokenId: string,
  collectionAddress: string,
): Promise<{ askOrders: AskOrder[]; transactions: Transaction[] }> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getCollectionActivity($tokenId: BigInt!, $address: ID!) {
          nfts(where:{tokenId: $tokenId, collection: $address}) {
            transactionHistory(orderBy: timestamp, orderDirection: desc) {
              ${getBaseTransactionFields()}
                nft {
                  ${getBaseNftFields()}
                }
            }
            askHistory(orderBy: timestamp, orderDirection: desc) {
                id
                block
                timestamp
                orderType
                askPrice
                seller {
                  id
                }
                nft {
                  ${getBaseNftFields()}
                }
            }
          }
        }
      `,
      { tokenId, address: collectionAddress },
    )

    if (res.nfts.length > 0) {
      return { askOrders: res.nfts[0].askHistory, transactions: res.nfts[0].transactionHistory }
    }
    return { askOrders: [], transactions: [] }
  } catch (error) {
    console.error('Here')
    console.error('Failed to fetch token Activity', error)
    return {
      askOrders: [],
      transactions: [],
    }
  }
}

/**
 * Get the most recently listed NFTs
 * @param first Number of nfts to retrieve
 * @returns NftTokenSg[]
 */
export const getLatestListedNfts = async (first: number): Promise<TokenMarketData[]> => {
  let result = []
  try {
    const apiRes = await axios.get(`${API_NFT}/collections/tokens/newest?onOrder=1&updateDesc=1&first=${first}`)
    if (apiRes.status) {
      result = result.concat(
        apiRes.data.data.map((nft) => {
          return {
            tokenId: nft.tokenId,
            metadataUrl: null,
            currentAskPrice: nft.onOrderPrice,
            currentSeller: nft.order.creator,
            latestTradedPriceInETH: null,
            tradeVolumeETH: null,
            totalTrades: null,
            isTradable: true,
            otherId: null,
            collection: {
              id: nft.collection.address,
            },
            updatedAt: nft.updated_at,
            transactionHistory: [],
          }
        }),
      )
    }
  } catch (error) {
    console.error('Failed to fetch NFTs market data', error)
  }

  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getLatestNftMarketData($first: Int) {
          nfts(where: { isTradable: true }, orderBy: updatedAt , orderDirection: desc, first: $first) {
            ${getBaseNftFields()}
            collection {
              id
            }
          }
        }
      `,
      { first },
    )
    result = result.concat(res.nfts)
  } catch (error) {
    console.error('Failed to fetch NFTs market data', error)
  }
  return result
}

/**
 * OTHER HELPERS
 */

export const getMetadataWithFallback = (apiMetadata: ApiResponseCollectionTokens['data'], bunnyId: string) => {
  // The fallback is just for the testnet where some bunnies don't exist
  return (
    apiMetadata[bunnyId] ?? {
      name: '',
      collection: {
        name: 'Pancake Bunnies',
        description: '',
      },
      image: '',
    }
  )
}

export const fetchWalletTokenIdsForCollections = async (
  account: string,
  collections: ApiCollections,
): Promise<TokenIdWithCollectionAddress[]> => {
  const walletNftPromises = map(collections, async (collection): Promise<TokenIdWithCollectionAddress[]> => {
    const { address: collectionAddress } = collection
    let tokensWithCollectionAddress = []
    if (isAddress(collectionAddress)) {
      const contract = getGhostBaseCollectionContract(collectionAddress)
      const results = await contract.tokensOfOwner(account)
      const tokenIds = results.map((v) => parseInt(v.toString(), 10))
      const nftLocation = NftLocation.WALLET
      tokensWithCollectionAddress = tokenIds.map((tokenId) => {
        return { tokenId, collectionAddress, nftLocation }
      })
    }
    return tokensWithCollectionAddress
  })
  const nftsFromApi = await getCollectionOwnerTokensFromApi(account)

  const obj = Object.values(nftsFromApi.data)
    .filter((nft) => !isNumericCheck(nft.tokenId))
    .map((nft) => {
      const nftLocation = nft.onOrder ? NftLocation.FORSALE : NftLocation.WALLET
      return {
        tokenId: nft.tokenId,
        collectionAddress: nft.collection.address,
        nftLocation,
      }
    })
  const walletNfts = await Promise.all(walletNftPromises)
  return walletNfts.flat().concat(obj)
}

/**
 * Helper to combine data from the collections' API and subgraph
 */
export const combineCollectionData = (
  collectionApiData: ApiCollection[],
  collectionSgData: CollectionMarketDataBaseFields[],
): Record<string, Collection> => {
  const collectionsMarketObj: Record<string, CollectionMarketDataBaseFields> = collectionSgData.reduce(
    (prev, current) => {
      return current ? { ...prev, [current.id]: { ...current } } : prev
    },
    {},
  )

  return collectionApiData.reduce((accum, current) => {
    const collectionMarket = collectionsMarketObj[current.address.toLowerCase()] || {
      active: true,
      id: current.address.toLowerCase(),
      totalTrades: '0',
      totalVolumeETH: '0',
      whitelistChecker: '0x0000000000000000000000000000000000000000',
      creatorFee: current.creatorFee.toString(),
      refererFee: current.refererFee.toString(),
      numberTokensListed: '0', // will onorder fix
    }

    const collection: Collection = {
      ...current,
      ...collectionMarket,
    }

    if (current.name) {
      collection.name = current.name
    }

    return {
      ...accum,
      [current.address]: collection,
    }
  }, {})
}

/**
 * Evaluate whether a market NFT is in a users wallet, their profile picture, or on sale
 * @param tokenId string
 * @param tokenIdsInWallet array of tokenIds in wallet
 * @param tokenIdsForSale array of tokenIds on sale
 * @param profileNftId Optional tokenId of users' profile picture
 * @returns NftLocation enum value
 */
export const getNftLocationForMarketNft = (nft: NftToken, marketData: TokenMarketData): NftLocation => {
  if (marketData && parseFloat(marketData.currentAskPrice) > 0) {
    return NftLocation.FORSALE
  }
  if (!marketData && nft.onOrder) {
    return NftLocation.FORSALE
  }
  if (!marketData && !nft.onOrder) {
    return NftLocation.WALLET
  }
  console.error(`Cannot determine location for tokenID ${nft.tokenId}, defaulting to NftLocation.WALLET`)
  return NftLocation.WALLET
}

/**
 * Construct complete TokenMarketData entities with a users' wallet NFT ids and market data for their wallet NFTs
 * @param walletNfts TokenIdWithCollectionAddress
 * @param marketDataForWalletNfts TokenMarketData[]
 * @returns TokenMarketData[]
 */
export const attachMarketDataToWalletNfts = (
  walletNfts: TokenIdWithCollectionAddress[],
  marketDataForWalletNfts: TokenMarketData[],
): TokenMarketData[] => {
  const walletNftsWithMarketData = walletNfts.map((walletNft) => {
    const marketData = marketDataForWalletNfts.find(
      (marketNft) =>
        marketNft.tokenId === walletNft.tokenId &&
        marketNft.collection.id.toLowerCase() === walletNft.collectionAddress.toLowerCase(),
    )
    return (
      marketData ?? {
        tokenId: walletNft.tokenId,
        collection: {
          id: walletNft.collectionAddress.toLowerCase(),
        },
        nftLocation: walletNft.nftLocation,
        metadataUrl: null,
        transactionHistory: null,
        currentSeller: null,
        isTradable: null,
        currentAskPrice: null,
        latestTradedPriceInETH: null,
        tradeVolumeETH: null,
        totalTrades: null,
        otherId: null,
      }
    )
  })
  return walletNftsWithMarketData
}

/**
 * Attach TokenMarketData and location to NftToken
 * @param nftsWithMetadata NftToken[] with API metadata
 * @param nftsForSale  market data for nfts that are on sale (i.e. not in a user's wallet)
 * @param walletNfts market data for nfts in a user's wallet
 * @param tokenIdsInWallet array of token ids in user's wallet
 * @param tokenIdsForSale array of token ids of nfts that are on sale
 * @returns NFT[]
 */
export const combineNftMarketAndMetadata = (
  nftsWithMetadata: NftToken[],
  nftsForSale: TokenMarketData[],
): NftToken[] => {
  const completeNftData = nftsWithMetadata.map<NftToken>((nft) => {
    // Get metadata object
    const marketData = nftsForSale.find((marketNft) => {
      return (
        marketNft.tokenId === nft.tokenId &&
        marketNft.collection.id.toLowerCase() === nft.collectionAddress.toLowerCase()
      )
    })
    const location = getNftLocationForMarketNft(nft, marketData)
    return { ...nft, marketData, location }
  })
  return completeNftData
}

/**
 * Get in-wallet, on-sale & profile pic NFT metadata, complete with market data for a given account
 * @param account
 * @param collections
 * @param profileNftWithCollectionAddress
 * @returns Promise<NftToken[]>
 */
export const getCompleteAccountNftData = async (account: string, collections: ApiCollections): Promise<NftToken[]> => {
  const walletNftIdsWithCollectionAddress = await fetchWalletTokenIdsForCollections(account, collections)

  const orderDataForSaleNfts = await getOrderDataFromApi(account.toLowerCase())
  const marketDataForSaleNfts = await getNftsMarketData({ currentSeller: account.toLowerCase() })

  const marketAndOrderCombine = orderDataForSaleNfts.concat(marketDataForSaleNfts)
  const forSaleNftIds = marketAndOrderCombine.map((nft) => {
    return { collectionAddress: nft.collection.id, tokenId: nft.tokenId }
  })
  const metadataForAllNfts = await getNftsFromDifferentCollectionsApi([
    ...forSaleNftIds,
    ...walletNftIdsWithCollectionAddress,
  ])

  const completeNftData = combineNftMarketAndMetadata(metadataForAllNfts, marketAndOrderCombine)

  return completeNftData
}

/**
 * Fetch distribution information for a collection
 * @returns
 */
export const getCollectionDistributionApi = async <T>(collectionAddress: string): Promise<T> => {
  const res = await fetch(`${API_NFT}/collections/${collectionAddress}/distribution`)
  if (res.ok) {
    const data = await res.json()
    return data
  }
  console.error(`API: Failed to fetch NFT collection ${collectionAddress} distribution`, res.statusText)
  return null
}

export const ipfsToPath = (ipfsHash, gateway = 'ipfs://'): string => {
  // return `${PINATA_GATEWAY}${ipfsHash}`
  return `${gateway}${ipfsHash}`
}
export const ipfsToPathViaPinata = (ipfsHash): string => {
  return ipfsToPath(ipfsHash, PINATA_GATEWAY)
}
export const replaceIpfsToGateway = (ipfsPath): string => {
  // return `${PINATA_GATEWAY}${ipfsHash}`
  return ipfsPath ? ipfsPath.replace('ipfs://', PINATA_GATEWAY) : ''
}

/**
 * Post image data to nft by API
 * @returns
 */
export const uploadNftImage = async (file): Promise<ApiImageUploadProps> => {
  const filetype = file.type
  const filename = file.name
  const data = new FormData()
  data.append('file', file)
  data.append('pinataOptions', '{"cidVersion": 0}')
  // data.append('pinataMetadata', `{"name": "${APP_CHAIN_ID}-${filename}"}`)
  data.append('pinataMetadata', `{"name": "${APP_CHAIN_ID}-${filename}"}`)

  const config = {
    headers: {
      Authorization: `Bearer ${PINATA_JWT}`,
      'Content-Type': 'multipart/form-data',
    },
  }
  const res = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', data, config)
  if (res.status === 200) {
    return {
      ipfsHash: res.data.IpfsHash,
      filetype,
    }
  }
  console.error('Failed to post image upload', res)
  return null
}

export const uploadJsonToIPFS = async (obj): Promise<string> => {
  const data = JSON.stringify({
    pinataOptions: {
      cidVersion: 0,
    },
    pinataMetadata: {
      name: `${APP_CHAIN_ID}-${obj.name}.json`,
    },
    pinataContent: obj,
  })

  const config = {
    headers: {
      Authorization: `Bearer ${PINATA_JWT}`,
      'Content-Type': 'application/json',
    },
  }

  const res = await axios.post('https://api.pinata.cloud/pinning/pinJSONToIPFS', data, config)
  if (res.status === 200) {
    return res.data.IpfsHash
  }
  console.error('Failed to post json upload', res)
  return null
}

/**
 * Post collection data by api
 * @returns
 */
export const checkKeyIsUnique = async (_key: string, _value: any) => {
  const res = await axios.get(`${API_NFT}/collections/isunique?key=${_key}&value=${_value}`)
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to check unique', res)
  return null
}

/**
 * Post collection data by api
 * @returns
 */
export const sendCreateCollection = async (collection: ApiCollectionDataProps) => {
  const updateData = { ...collection }
  if (updateData.attributes) {
    updateData.attributes = collection.attributes.filter((v) => !!v.trait_type && !!v.value)
  }
  const res = await axios.post(`${API_NFT}/collections/store`, updateData)
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to post collection data', res)
  return null
}

/**
 * Post collection data by api
 * @returns
 */
export const updateCollection = async (collection: Collection, data: any) => {
  // filter attributes
  const updateData = { ...data }
  if (updateData.attributes) {
    updateData.attributes = data.attributes.filter((v) => !!v.trait_type && !!v.value)
  }
  const res = await axios.post(`${API_NFT}/collections/update`, { ...collection, ...updateData })
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to post collection data', res)
  return null
}

/**
 * Post template data by api
 * @param account
 * @returns Template from api
 */
export const sendTemplateApi = async (template: ApiResponseTemplate): Promise<ApiResponseTemplates> => {
  let res
  if (template.id) {
    res = await axios.patch(`${API_NFT}/templates/${template.id}`, { ...template })
  } else {
    res = await axios.post(`${API_NFT}/templates`, { ...template })
  }
  if (res.status === 200) {
    return res.data
  }
  console.error(`API: Can't update templates`, res.status)
  return null
}

export const sendLaunchpadPageApi = async (
  launchpadPage: ApiResponseLaunchpadPage,
): Promise<ApiResponseLaunchpadPages> => {
  let res
  if (launchpadPage.id) {
    res = await axios.patch(`${API_NFT}/launchpads/${launchpadPage.address}/${launchpadPage.id}`, { ...launchpadPage })
  } else {
    res = await axios.post(`${API_NFT}/launchpads/${launchpadPage.address}`, { ...launchpadPage })
  }
  if (res.status === 200) {
    return res.data
  }
  console.error(`API: Can't update launchpad page`, res.status)
  return null
}

export const deleteLaunchpadPageApi = async (
  launchpadPage: ApiResponseLaunchpadPage,
): Promise<ApiResponseLaunchpadPages> => {
  const res = await axios.delete(`${API_NFT}/launchpads/${launchpadPage.address}/${launchpadPage.id}`)
  if (res.status === 200) {
    return res.data
  }
  console.error(`API: Can't update launchpad page`, res.status)
  return null
}

/**
 * Post collection data by api
 * @returns
 */
export const updateNftTokenDetail = async (data) => {
  const res = await axios.post(`${API_NFT}/tokens/update`, { data })
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to post collection data', res)
  return null
}

export const fetchMaxApiTokenId = async (collectionAddress) => {
  const res = await axios.get(`${API_NFT}/collections/${collectionAddress}/maxtokenid`)
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to fetch max id', res)
  return null
}

export const fetchAllTokenIds = async (collectionAddress) => {
  const res = await axios.get(`${API_NFT}/collections/${collectionAddress}/alltokenids`)
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to fetch all token id', res)
  return null
}

/**
 * Post cancel order
 * @returns
 */
export const cancelOrderApi = async (tokenUID) => {
  const res = await axios.post(`${API_NFT}/orders/cancel`, { tokenUID })
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to cancel order', res)
  return null
}

/**
 * Get order data by api
 * @returns
 */
export const getOrderData = async (tokenUID) => {
  const res = await axios.get(`${API_NFT}/orders?uid=${tokenUID}`)
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to post collection data', res)
  return null
}

export const colletionDeleteApi = async (address, owner) => {
  const res = await axios.post(`${API_NFT}/collections/destroy`, { address, owner })
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to delete collection', res)
  return null
}

/**
 * Post nft data by api
 * @returns
 */
export const sendApiNftData = async (nft: ApiNewNftDataProps): Promise<ApiNewNftDataProps> => {
  const res = await axios.post(`${API_NFT}/tokens/store`, { ...nft })
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to post nft data', res)
  return null
}

/**
 * Post nft data by api
 * @returns
 */
export const sendApiOrderSignature = async (
  creator: string,
  price: string,
  nft: NftToken,
  data: string,
  sig: SignatureDataProps,
): Promise<OrderDataProps> => {
  const res = await axios.post(`${API_NFT}/orders/create`, {
    tokenUID: nft.tokenUID,
    creator,
    price,
    data,
    v: sig.v,
    r: sig.r,
    s: sig.s,
  })
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to post nft data', res)
  return null
}

/**
 * Post nft data by api
 * @returns
 */
export const sendIntroductionData = async (
  collection: string,
  tokenId: string,
  content: string,
  owner: string,
): Promise<ApiIntroductionDataProps> => {
  const res = await axios.post(`${API_NFT}/collections/${collection}/tokens/${tokenId}/introductions`, {
    content,
    owner,
  })
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to post introduction', res)
  return null
}

/**
 * Post nft data by api
 * @returns
 */
export const sendTemplateData = async (content: string, owner: string): Promise<ApiIntroductionDataProps> => {
  const res = await axios.post(`${API_NFT}/templates/store`, {
    content,
    owner,
  })
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to post introduction', res)
  return null
}

/**
 * Post nft data by api
 * @returns
 */
export const fetchIntroductionSingle = async (collection: string, tokenId: string) => {
  const res = await axios.get(`${API_NFT}/collections/${collection}/tokens/${tokenId}/introductions`)
  if (res.status === 200) {
    return res.data
  }
  console.error('Failed to get introduction', res)
  return null
}

export function isNumericCheck(value: any): boolean {
  return !Number.isNaN(Number(value))
}

export const getFileBaseType = (_filetype: string): string => {
  let basetype = 'other'
  if (_filetype.match(/image/)) {
    basetype = 'image'
  } else if (_filetype.match(/video/)) {
    basetype = 'video'
  } else if (_filetype.match(/audio/)) {
    basetype = 'audio'
  }
  return basetype
}

export const getLaunchpads = async (): Promise<Launchpad[]> => {
  const contract = getGhostPadFactoryContract()
  const collections = await contract.getCollections()
  const launchpadInfos = []
  const launchpadInfoCalls = collections
    .filter((c) => {
      return c !== '0x393783f725884a5629bC94756FCe878566791dEE'
    })
    .flatMap((collection) => {
      return [
        {
          address: contract.address,
          name: 'launchPadInfos',
          params: [collection],
        },
        {
          address: contract.address,
          name: 'isActive',
          params: [collection],
        },
      ]
    })
  const launchpadInfoResults = await multicall(GhostPadFactoryAbi, launchpadInfoCalls)
  launchpadInfoCalls.forEach((call, index) => {
    if (call.name === 'launchPadInfos') {
      launchpadInfos[call.params[0]] = { ...launchpadInfos[call.params[0]], ...launchpadInfoResults[index] }
    } else {
      const obj = {}
      obj[call.name] = launchpadInfoResults[index][0]
      launchpadInfos[call.params[0]] = { ...launchpadInfos[call.params[0]], ...obj }
    }
  })
  return Object.keys(launchpadInfos).map((address) => {
    const { collection, currentSupply, isActive, startBlock, endBlock, maxAlloc, price, whitelist } =
      launchpadInfos[address]
    return {
      id: collection,
      address: collection,
      active: isActive,
      startBlock: startBlock.toString(),
      endBlock: endBlock.toString(),
      maxAlloc: maxAlloc.toString(),
      currentSupply: currentSupply.toString(),
      price: formatBigNumber(price).toString(),
      whitelist,
    }
  })
}

export const updateCollectionNftsDetails = async (collectionAddress, maxSize = 50) => {
  if (!isAddress(collectionAddress)) {
    return false
  }
  const contract = getGhostBaseCollectionContract(collectionAddress)
  const totalSupply = await contract.totalSupply()
  const intTotalSupply = parseInt(totalSupply.toString())
  const tokenIds = []
  const calls = []
  // const fetchMaxTokenId = await fetchMaxApiTokenId(collectionAddress)
  const fetchTokenIds = await fetchAllTokenIds(collectionAddress)
  const intTokenIds = fetchTokenIds.filter((v) => isNumericCheck(v)).map((v) => parseInt(v))
  const needTokenIds = Array.from({ length: intTotalSupply }, (v, k) => k + 1).filter((x) => !intTokenIds.includes(x))
  if (needTokenIds.length === 0) {
    return false
  }
  for (let index = 0; index < needTokenIds.length; index++) {
    const tokenId = needTokenIds[index]
    tokenIds.push(tokenId)
    calls.push({
      address: contract.address,
      name: 'tokenURI',
      params: [tokenId],
    })
    if (tokenIds.length >= maxSize) {
      break
    }
  }
  const results = await multicall(GhostBaseCollectionAbi, calls)

  const postData = []

  results.forEach(async (value, index) => {
    postData.push({
      collectionAddress,
      tokenId: tokenIds[index].toString(),
      tokenUID: `${collectionAddress}-${tokenIds[index].toString()}`,
      tokenURI: value[0],
    })
  })
  await updateNftTokenDetail(postData)
  return true
}

export const getEthUsdPriceOnDev = async () => {
  const res = await axios.get(`https://api.coingecko.com/api/v3/coins/${CHAIN_TOKEN.ticker}`)
  if (res.status === 200 && res.data) {
    return res.data.market_data.current_price.usd
  }
  console.error('Failed to get introduction', res)
  return null
}

export const getEthUsdPrice = async () => {
  if (APP_CHAIN_ID === 1337 || APP_CHAIN_ID === 9234) {
    return getEthUsdPriceOnDev()
  }
  const provider = new ethers.providers.JsonRpcProvider(RPC_URL)
  // The address of the contract which will provide the price of ETH
  const addr = getChainLinkAddress()
  // We create an instance of the contract which we can interact with
  const priceFeed = new ethers.Contract(addr, aggregatorV3InterfaceABI, provider)
  // We get the data from the last round of the contract
  const latestAnswer = await priceFeed.latestAnswer()
  // Determine how many decimals the price feed has (10**decimals)
  const decimals = await priceFeed.decimals()
  const result = Number((10 ** decimals / parseInt(latestAnswer.toString())).toFixed(2))
  // We convert the price to a number and return it
  return result
}
