import { FooterLinkType } from '@ghost-nft/ghost-uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('Blog'),
        href: 'https://medium.ghostnft.io',
      },
    ],
  },
  {
    label: t('Help'),
    items: [
      {
        label: t('Community'),
        href: 'https://docs.ghostnft.io/info/contact-us',
      },
      {
        label: t('Troubleshooting（in progress）'),
        href: 'https://docs.ghostnft.io/troubleshooting',
      },
      {
        label: t('Guides'),
        href: 'https://docs.ghostnft.io/get-started',
      },
    ],
  },
  {
    label: t('Developers'),
    items: [
      {
        label: t('Github（in progress）'),
        href: 'https://github.com/ghost-nft',
      },
      {
        label: t('Documentation'),
        href: 'https://docs.ghostnft.io',
      },
    ],
  },
]
